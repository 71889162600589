/* Reset default margin and padding */
@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");
.varela-round-regular {
  font-family: "Varela Round", sans-serif;
  font-weight: 400;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
  font-family: "Varela Round", sans-serif;
  direction: rtl;
}

html {
  background-color: #fffff8;
}
a {
  text-decoration: none;
  color: #593400;
}
.emoji {
  font-size: 25px;
}

/* Global styles */
body {
  font-family: Arial, sans-serif;
  overflow-x: hidden;
  background-color: #f9eddc;
  color: #333;
  padding: 0;
}

h2 {
  font-size: 1.75rem;
  color: #000;
}
h1,
h3,
h4,
h5 {
  color: black;
}

h1 {
  font-size: 2.5rem;
}

p {
  font-size: 1.375rem;
  margin: 1rem 0;
}

.side-social-links {
  position: fixed;
  top: 25%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  /* background-color: #fff; */
  border-radius: 5px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  z-index: 1000;
}
.side-social-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  /* color: #333; */
  /* outline: 2px solid #593400; Adjust color and width */
  border-radius: 1150%;
  background-color: #f9eddc;
  transition: all 1s ease; /* Change duration to 0.5s for slower spin */
}

.side-social-link:hover {
  transform: rotatey(180deg); /* Use rotateX for horizontal spin */
}

.side-social-link img {
  width: 30px;
  height: 30px; 
}

/* Navbar styles */
.navbar {
  background: #e5eee3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 8vh;
  z-index: 10;
}
/* navbar */
.navbar-logo {
  align-self: center;
  position: relative;
  left: 1rem;
  top: 3px;
}

.navbar-links {
  display: flex;
  flex-grow: 1;
}

.navbar-links ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
}

.navbar-links li {
  padding: 1rem 2vw;
}

.navbar-links a {
  color: #593400;
  font-weight: 100;
  text-decoration: none;
  font-size: 1.2rem;
  display: block;
  transition: all 0.2s;
}

.navbar-links a:hover {
  color: black;
}

/* Highlight style */
.highlight {
  background-color: #593400;
  /* padding: 0.25rem 0.5rem; */
  border-radius: 0.25rem;
  box-shadow: 0.2rem 0.2rem 0.3rem #b19a86;
  /* font-weight: bold; */
  color: #fffff8;
  line-height: 1.5;
  /* padding-left: 1%; */
  /* padding-right: 1%; */
}

/* Main content styles */

.about-leg-section h2 {
  margin-bottom: 5%;
}


.about-leg-section a:hover {
  border-radius: 0 100px 0 100px;
}

.contact-section {
  margin-bottom: 10%;
  margin-top: 5%;
  text-align: center;
}
.contact-section h2 {
  margin-bottom: 5%;
}
.contact-section p {
  font-size: 1.2em;
}
.home {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #333;
  direction: rtl;
  min-height: 100vh;
  justify-content: center;

}

.about-leg-page {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #333;
  direction: rtl;
  top: 13.5vh;
  min-height: 100vh;
  justify-content: center;
  margin-bottom: 4vh;
}
.about-leg-page h1 {
  position: relative;
  top:3.5vh;
  color: black;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 40px;
}
.contact-page {
  background-color: #fffff8;
  margin-left: 5%;
  margin-right: 5%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #333;
  direction: rtl;
  top: 8vh;
  min-height: 100vh;
  justify-content: center;
  gap: 5px;
  line-height: 1.1;
  margin-bottom: 7vh;
  margin-top:4vh;
}

.contact-page .some-text {
  font-weight: 500;
  color: #593400;
  margin-bottom: 0;
  font-size: 18px;
}
.contact-page a {
  color: black;
}
.contact-page a:hover {
  color: #593400;
}

.contact-page img {
  position: relative;
  top: 5px;
  left: 10px;
  border-radius: 100%;
  background-color: white;
}
.contact-page-social {
  line-height: 1;
}

  .contact-page h1 {
    position: relative;
    top: 5vh;
    margin-bottom: 50px;
}

.map {
  position: relative;
  bottom: 10px;
  margin-bottom: 30px;
}
.about-page {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #333;
  direction: rtl;
  /* top: 3vh; */
  top: 8vh;
  margin-bottom: 7vh;
  margin-top:4vh;
  min-height: 100vh;
  justify-content: center;
  margin-left: 5%;
  margin-right: 5%;
  background-color: #fffff8;
  padding-bottom: 40px;
}

.about-header {
  position: relative;
  margin-bottom: 50px;
  margin-top:5vh;
  /* top: 5vh; */
  /* padding:5px; */
}

.about-header h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: black;
}
.about-header h2 {
  color: #593400;
  font-weight: 400;
  font-size: 20px;
}

.about-header p {
  font-size: 16px;
  font-weight: normal;
}

.about-content {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-around; */
  /* max-width: 1000px; */
}

.contact p {
  color: black;
  font-size: 20px;
}
.contact-page-social {
  font-size: 16px;
}
.contact a {
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
  color: #593400;
}
.contact a:hover {
  color: black;
}
.footer {
  position: relative;
  /* width: 100vw; */
  bottom: 0;
  background-color: #fff;
  color: #593400;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 5vh;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
  direction: ltr;

}

.social-links {
  position: absolute;
  display: flex;
  gap: 0.625rem;
  /* justify-content: flex-end;  Uncomment for right-alignment */
  /* font-size: 0.25rem;  Uncomment for smaller size */
}

.social-links a {
  text-decoration: none;
}

.social-links .social-link img {
  width: 1.5rem;
  height: 1.5rem;
  transition: transform 0.2s ease-in-out; /* Smooth transition */
  filter: grayscale(100%); /* Start with grayscale filter */
}

.social-links .social-link:hover img {
  transform: scale(1.1); /* Slightly scale up on hover */
  filter: grayscale(0%); /* Remove grayscale on hover */
}

.site-links {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.site-links a {
  color: #fffff8;
  text-decoration: none;
  transition: all 0.2s;
}

.copyright {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  font-weight: 500;
  color: black;
}

section {
  padding-left: 10%;
  padding-right: 10%;
}

/* Color palette */
.hero-section {
  width: 100vw;
  height: 100vh;
  display: inline-table;
  /* position: relative; */
  background-image: url("../public/feet.webp");
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.hero-content {
  position: relative;
  top: 8vh;
  padding: 4% 2%;
  margin-top: 5%;
  margin-left: 15%;
  margin-right: 15%;
  z-index: 1; 
}

.hero-section p {
  font-size: 1.5rem;
  color: black;
}

.about-section {
  margin-bottom: 5%;
  margin-top: 10%;
  margin-left: 5%;
  margin-right: 5%;
  background-color: #fffff8;
  padding: 50px 0;
}
.decoration {
  position: relative;
}
/* .about-leg-section h2 {
  margin-right: 152px;
} */

/* .contact-section h2 {
  margin-right: 220px;
} */
.testimonials-section h2 {
  /* margin-right: 180px; */
  padding-top: 50px;
  padding-bottom: 30px;
}
.testimonials-section button{
     translate: 0% 80%;
}
#decoration-one {
  left: 140px;
  top: 23px;
  width: 200px;
}
#decoration-two {
  left: 130px;
  top: 23px;
}
#decoration-three {
  left: 160px;
  width: 230px;
  top: 23px;
}
#decoration-four {
  left: 140px;
  width: 200px;
  top: 23px;
}

.about-container {
  max-width: 100%;
  margin: 0 auto;
}

.about-content {
  display: flex;
  flex-direction: row;
}

.about-image-container {
  width:50%;
}

.about-image-container img {
  border-radius: 81% 33% 31% 48% / 41% 60% 54% 70%;

  /* border-radius: 0 20% 0 20%; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
/* About Section */
.about-section .about-text-container {
  width: 50%;
  align-self: center;
  text-align: right;
  padding-right: 5%;
  padding-left: 5%;
}

.about-section .about-text-container p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 30px;
  width: 80%;
}

/* About Me page */
.about-text-container {
  width:50%;
  align-self: center;
  text-align: right;
  padding-right: 5.5%;
  padding-left: 5%;
}

.about-text-container p {
  font-size: 16px;
  line-height: 1.3;
  width: 130%;
}


.about h2 {
  font-weight: bold;
}
.about-leg-section {
  /* margin-bottom: 8%; */
  margin-top: 5%;
}
.about-leg h2 {
  font-weight: bold;
}

.about-leg-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.25rem;
  margin: 1rem 0;
  cursor: pointer;
}

.about-leg-card {
  background-color: #fffff8;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  width: 70%;
  max-width: 250px;
  /* transition: transform 0.3s ease; */
  border: none;
}
.about-leg-card a {
  font-weight: bold;
  color: black;
  transition: color 0.3s;
  font-size: larger;
}

.about-leg-card:hover {
  background-color: #e5eee3;  
}
.about-leg-card a {
font-weight: 200
}
.about-leg-card a p {
  font-weight: 900;
  }

.about-leg-image {
  max-width: 200px;
  height: 200px;
  border-radius: 81% 33% 31% 48% / 41% 60% 54% 70%;
}


/* General Styles */

.page-single-about-leg {
  margin-top: 8vh;
  margin-left: 10%;
  margin-right: 10%;
  padding-top: 5%;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  background-color: #fffff8;
  background-repeat: no-repeat;
  direction: rtl;
}

/* Headings */
.h1-single-about-leg {
  text-align: center;
}

/* Paragraphs */
.h2-single-about-leg {
  font-size: 1.1rem;
  margin-bottom: 20px;
  padding: 3%;
  line-height: 1.6;
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
}

.list-single-about-leg li {
  list-style-type: none;
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
}

.list-single-about-leg ul {
  margin: 20px;
}

.img-single-about-leg {
  width: 50vh;
  width: auto;
  height: 100vh;
  display: block;
  margin: 30px auto;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Button */
.btn-book-treatment {
  display: block;
  width: 200px;
  margin: 30px auto 0;
  padding: 10px;
  text-align: center;
  background-color: #007bff;
  color: #fffff8;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.btn-book-treatment:hover {
  background-color: #0056b3;
}
.post-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem;
  background-color: #f7f7f7;
  font-family: 'Helvetica Neue', sans-serif;
  direction: rtl;
  position: relative;
  top:8vh;
  z-index:0;
  
}

.post-page-container::before {
  content: "";
  position: fixed;
  top: 20vh;
  left: 0;
  width: 90vw;
  height: 50vh;
  background: #e5eee3;
  border-top-left-radius: 50% 90%;
  border-bottom-left-radius: 50% 10%;
  border-top-right-radius: 50% 10%;
  border-bottom-right-radius: 51% 90%;
  rotate: 20deg;
  

  z-index: -1;
}

.post-content-container {
  max-width: 800px;
  text-align: right;
}

.post-title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #333;
}

.post-section {
  background-color: #fff;
  padding: 18px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}
.post-section p{font-size: 16px;}
.post-section p strong{font-size: 16px;}



.post-question {
  font-size: 20px;
  margin-bottom: 1rem;
  color: #555;
}

.post-list {
  list-style-type: none;
  padding: 0;
  margin-top: 1rem;
  font-size:16px;
}

.post-list-item {
  margin-bottom: 0.5rem;
}

.post-image-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.post-image {
  width:400px;
  height: 400px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.testimonials-section {
  padding-top: 1rem;
  padding-bottom: 4rem;
  background-color: transparent;
  text-align: center;
}

.testimonial-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.testimonial {
  background-color: #fff;
  border-radius: 50px 50px 50px 50px;
  padding: 20px;
  transition: transform 0.3s;
  position: relative;
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: fit-content;
}

.testimonial::before {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 35px;
  border-style: solid;
  border-width: 25px 25px 0;
  border-color: #fff transparent;
}

.testimonial:hover {
  transform: translateY(-5px);
}

.testimonial-quote {
  font-size: 18px;
  flex-grow: 1;
  text-align: center;
}

.testimonial-info {
  font-size: 14px;
  color: #666;
  align-items: center;
  
}

.testimonial-name {
  text-align: center;
  font-size: 16px;
  order: 2;
}


.mobile-navbar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #e5eee3;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* margin-bottom: -5vh; */
  z-index: 100;
  left: 0;
  right: 0;
  top:0;
}

.mobile-navbar-container {
  background-color: #e5eee3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.mobile-navbar-logo {
  display: flex;
  align-items: center;
  z-index:99;
}

.mobile-navbar-toggle {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 24px;
  cursor: pointer;
  right: 20px;
  bottom: 9px;
}

.mobile-navbar-toggle span {
  width: 100%;
  height: 3px;
  background-color: #333;
}

.mobile-navbar-links {
  position: fixed;
  top: 60px;
  right: 0;
  width: 100%;
  background-color: #e5eee3;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}

.mobile-navbar-links.open {
  transform: translateY(-8%);
}

.mobile-navbar-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-navbar-links li {
  padding: 1rem;
}

.mobile-navbar-links li:last-child {
  border: none;
  margin-bottom: 20px;
}

.mobile-navbar-links a {
  color: #593400;
  text-decoration: none;
}


#testimonials-page{
  min-height: 100vh;
    top: 120px;
    position: relative;
    margin-bottom: 120px;
}
#testimonials-page .section-title{
text-align: center;
margin-bottom: 40px;}

.gift-card-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.envelope-container {
  position: relative;
  width: 500px;
  height: 350px;
  cursor: pointer;
}

.envelope {
  position: relative;
  width: 100%;
  height: 75%;
  transition: transform 0.5s ease;
  background-color: #fff;
  border: 1px black;
  top: 50%;
  transform: translateY(-50%);
}

.envelope-flap {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 155px 250px 0 250px;
  border-color: #e5eee3 transparent transparent transparent;
  transition: border-width 0.5s ease;
}

.envelope.open .envelope-flap {
  border-width: 0 250px 175px 250px;
}

.envelope-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #333;
}

.logo {
  max-width: 120px;
  margin-bottom: 20px;
}

.gift-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 80%;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  opacity: 0;
  transition: transform 0.5s ease, opacity 0.5s ease;
  border-radius: 25px;
}

.gift-card.revealed {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}

.gift-card h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.gift-card p {
  font-size: 16px;
  margin-bottom: 20px;
}

.gift-card .logo2 {
  max-width: 120px;
  margin-top: 20px;
}

.gift-card button {
  margin: 30px auto 0;

  display: block;
  padding: 12px 24px;
  background-color: #e5eee3;
  color: #333;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.gift-card  button:hover {
  background-color: #d0e6c9;
}

.mobile-giftcard .gift-card.gift-card.revealed{
    transform: translate(-50%, -0%);
  }


  a.active  {
    color: black!important;
  }
@media screen and (max-width: 768px) {
  .gift-card-section {
    /* align-items: end; */
    height: 40vh;
    align-items: baseline;
  }
  .about-image-container img {
    width: 217.5px;
    height: 300px;
  }
  /* .gift-card-section {height: 52vh;} */
  .contact-section{
    margin-top:0;
  }
  .about-leg-section h2 {
    margin-bottom: 10%;
}
  .about-text-container h2{
    text-align:center;
  }
 
  .general-button {
    display: flex;
    justify-content: center;}
  .testimonial-container {
    grid-template-columns: 1fr;
    gap: 50px;
  }
  .myImage {
display: none;
}
.post-page-container {
  /* margin-top: -50px; */
  padding: 2rem;
}
  .gift-card.gift-card.revealed {
    position: relative;
    /* transform: translate(-50%, -130%); */
     opacity: 1;
    width: 100%;
    padding: 20px;
  }

  .envelope-container {
    display: none;
  }

  .envelope-content,
  .gift-card {
    font-size: 14px;
  }

  .logo,
  .logo2 {
    max-width: 80px;
  }
  h2 {
    font-size: 1.5rem;
  }
  .decoration {
    width: 96px !important;
    height: 45px !important;
    left: 0 !important;
    top: 15px !important;
  }
  .testimonials-section h2{
  margin-right:0px;
  }
  .about-leg-section h2,
  .contact-section h2 {
    margin-right: 0px !important;
  }
  .emoji {
    font-size: 25px;
  }
  .navbar-links a {
    font-size: 0.9rem;
  }
  .footer-content {
    align-items: center;
  }
  .copyright {
    text-align: right;
  }
  .social-links .social-link img {
    width: 1.2rem;
    height: 1.2rem;
  }

  .hero-section button {
    font-size: 14px;
    position: relative;
    display: inline-block;  }
  .hero-content {
    margin: 25% 0 15px 15px;
  }

  .about-container {
    flex-direction: column;
  }
  .about-image-container {
    margin-bottom: 20px;
  }
  .contact-section p {
    font-size: 16px;
  }
  .contact-page {
    padding: 20px 20px 20px 20px;
  }
  .navbar-logo img {
    width: 93.75px;
    height: 25px;
  }
  .p-about-leg {
    padding: 0px !important;
  }
  .contact-page p {
    font-size: 1rem;
  }
  .post-image {
  width: 250px!important;
  height: 250px!important;
  }
  .about-leg-image {max-width:150px;
  height: 150px;}

  .about-section .about-text-container p{
  width: 100%;
  text-align: center;
}
.about-leg-card {
width: 100%;
}

}

/* General button on homepage */
.general-button{
  position: relative;
  display: inline-block;
    background-color: #fffff8;
  color: #593400;
  border: 0.125rem solid #593400;
  padding: 0.625rem 1.25rem;
  font-size: 1rem;
  border-radius: 0.3125rem;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
   font-weight: 300;
}
.general-button{
  color: #593400;
}
.general-button:hover {
  transform: scale(1.1); 
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1); 
}
.about-leg-section .general-button{
  translate: 0% -80%;
}
.about-text-container{
  text-align: right;
}
@media (min-width: 901px) {
  .hero-content img{
   width: 360px;
   height: 270px; 
  }
}
@media (max-width: 900px) {
  
  .about-image-container{
    margin-bottom: 15px;
  }
  .about-section .about-content {
    flex-direction: column;
    text-align-last: center;
  }
  .about-section .about-image-container,
  .about-section .about-text-container {
    width: 100%;
  }
  .about-section .about-text-container p {
    width: 100%;
  }

  

   .about-content {
    flex-direction: column;
  }
  .about-image-container,
  .about-text-container {
    width: 100%;
    text-align: center;

  }
  .about-text-container p {
    width: 100%;
  }
 
}
.faq-section { 
  background: transparent;
  padding: 4rem 2rem;
  text-align: center;
  position: relative;
  overflow: hidden;
}

/* .section-header h2 {
  color: var(--primary-color);
  font-size: 2.5rem;
  margin-bottom: 1rem;
  position: relative;
} */

.faq-section .section-header h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 4px;
  background-color: var(--accent-color);
}

.faq-section .section-header p {
  color: var(--secondary-color);
  font-size: 1.1rem;
  max-width: 700px;
  margin: 0 auto;
}

.faq-container {
  max-width: 900px;
  margin: 0 auto;
  text-align: left;
}

.faq-item {
  background-color: white;
  border-radius: 15px;
  margin-bottom: 1.5rem;
  box-shadow: 0 10px 25px rgba(0,0,0,0.08);
  overflow: hidden;
  transition: all 0.3s ease;
  border: 1px solid transparent;
}

.faq-question:hover, .faq-item.active .faq-question  {
  background-color: #e5eee3;
}
.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 1.5rem;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align-last: right;
}

.faq-question h3 {
  margin: 0;
  font-size: 1.1rem;
  color: var(--primary-color);
  font-weight: 600;
  flex-grow: 1;
  margin-right: 1rem;
  text-align: right;
}

.faq-question i {
  color: var(--accent-color);
  transition: transform 0.4s ease;
  font-size: 1.2rem;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out, padding 0.4s ease-out;
  padding: 0 1.5rem;
  background-color: white;
  text-align: right;
}

.faq-answer p, .faq-answer ul {
  color: var(--secondary-color);
  line-height: 1.7;
  margin: 0.5rem 0;
  font-size: 100%;
}

.faq-item.active .faq-answer {
  max-height: 500px;
  padding: 1.5rem;
}

.fa-chevron-down:before {
  color:var(--primary-color);
}
